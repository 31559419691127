@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "HelveticaNeue";
  src: local("HelveticaNeue"),
    url("./fonts//HelveticaNeueLight.ttf") format("truetype");
  font-weight: normal;
}
* {
  font-family: HelveticaNeue, Arial, sans-serif !important;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hint .ant-select-selection-placeholder {
  color: #000000 !important;
}

.flex-2 {
  flex:2
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}